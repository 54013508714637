import React, { memo, useEffect, useMemo } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Flex } from 'native-base';

import { useGetAsset } from 'Hooks';

import { IconBaseComponent } from 'Components/utility/IconBaseComponent';

import i18n from 'Utilities/i18n';

interface Props {
  count?: number;
  identities?: Identity[];
  password: string;
  isInvalid?: boolean;
  onValidate?: (isValid: boolean) => void;
}

interface Requirement extends Identity {
  match?: boolean;
}

const PasswordRequirements: React.FC<Props> = ({ identities, password, onValidate, isInvalid }) => {
  const { getAssetInColorScheme } = useGetAsset();
  const checked = getAssetInColorScheme(
    'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.icons.FilledSuccess'
  );
  const unchecked = getAssetInColorScheme(
    'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.icons.CircleActionsSuccess'
  );

  const iconError = i18n.t('Global.microcopy.common.IconError');

  const filteredIdentities = useMemo(() => {
    if (!identities) {
      return [];
    }

    // This identity.data.key === 'min' must be removed as soon as we have more validations to add.
    return identities.filter((identity) => identity.code === 13 && identity.data.key === 'min');
  }, [identities]);

  const requirements: Requirement[] = useMemo(() => {
    if (!password) {
      return filteredIdentities;
    }

    return filteredIdentities.map((identity) => {
      const match = new RegExp(identity.data.regex).test(password.trim());

      return { match, ...identity };
    });
  }, [password, filteredIdentities]);

  useEffect(() => {
    const existsRemainingRequirement = requirements.find((requirement) => !requirement.match);

    onValidate && onValidate(!existsRemainingRequirement);
  }, [requirements, onValidate]);

  return (
    <Flex mb={2}>
      {requirements.map((requirement) => {
        return (
          <Flex direction="row" alignItems="center" key={requirement.data.key}>
            <IconBaseComponent
              tintColor={
                isInvalid
                  ? 'alert.warning.50.active.default'
                  : requirement.match
                  ? 'alert.success.50.active.default'
                  : 'neutral.40'
              }
              nativeID={`PasswordRequirements.${requirement.data.key}`}
              alt="error"
              iconImage={isInvalid ? iconError : requirement.match ? checked : unchecked}
              size={5.5}
              mr={2}
              ml={1}
            />
            <Text
              nativeID={`Login.content.signInForm.formField.password.helpText.${requirement.data.key}`}
              fontSize="s"
              right={2}
              color={
                isInvalid
                  ? 'alert.warning.50.active.default'
                  : requirement.match
                  ? 'alert.success.50.active.default'
                  : 'neutral.40'
              }
              fontFamily="labelBaseDefault"
              fontWeight="labelBaseDefault"
            >
              {i18n.t<string>(
                `Login.content.signInForm.formField.password.helpText.${requirement.data.key}`,
                {
                  count: requirement.data.value,
                }
              )}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default memo(PasswordRequirements);
