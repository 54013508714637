import React, { memo } from 'react';

import { Flex, IFlexProps, useMediaQuery } from 'native-base';

/**
 * For mobile devices, this component fills the entire screen.
 * For desktop, this component is centralized and fills around 50% of the entire screen.
 *
 * @param IFlexProps
 */
const ResponsiveContainer = ({ ...rest }: IFlexProps) => {
  const [isDesktopScreen] = useMediaQuery({
    minWidth: 480,
  });

  return (
    <Flex
      w={isDesktopScreen ? '500px' : '100%'}
      height={isDesktopScreen ? '520px' : '100%'}
      borderRadius={isDesktopScreen ? 40 : 0}
      alignSelf="center"
      bg="neutral.0"
      style={{
        paddingHorizontal: isDesktopScreen ? 80 : 16,
        marginTop: isDesktopScreen ? 100 : 0,
        paddingTop: isDesktopScreen ? 24 : 75,
      }}
      {...rest}
    />
  );
};

export default memo(ResponsiveContainer);
