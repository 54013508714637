import React, { forwardRef, memo, useCallback, useState } from 'react';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';

import { IInputProps } from 'native-base';

import { EyeIconComponent } from 'Components/utility/EyeIconComponent';
import { Input } from 'Components/utility/Input';

import ImageButton from '../ImageButton';

type InputPasswordProps = {
  isInvalid?: boolean;
  label: string;
  inputHelperText?: string;
  errorMessage?: string;
  placeholder?: string;
  isEyeIconVisible?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onFocus?: () => void;
};

type EyeIconElementProps = {
  handleShowPass: () => void;
  nativeID: string;
  showPassword: boolean;
  hasValue: boolean;
};

const EyeIconElement: React.FC<EyeIconElementProps> = memo(
  ({ handleShowPass, showPassword, nativeID, hasValue }) => {
    const tintColor = hasValue ? 'text.100' : 'inputField.text.emptyState';

    return (
      <ImageButton
        nativeID={`${nativeID}.toggle-visibility`}
        onPress={handleShowPass}
        pressableProps={{
          marginRight: 5,
        }}
        tintColor={tintColor}
      >
        <EyeIconComponent
          nativeID={`${nativeID}.eye-icon`}
          showIcon={showPassword}
          activeIcon={hasValue}
        />
      </ImageButton>
    );
  }
);

EyeIconElement.displayName = 'EyeIconElement';

const InputPassword = forwardRef(
  ({
    isInvalid = false,
    label = '',
    errorMessage,
    placeholder = '',
    nativeID = 'password',
    value,
    isEyeIconVisible = true,
    onBlur,
    onFocus,
    setFieldValue,
    ...rest
  }: IInputProps & InputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPass = useCallback(() => {
      onFocus && onFocus();
      setShowPassword((oldState) => !oldState);
    }, [onFocus]);

    const handleOnChangePassword = useCallback(
      (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        const password = e.nativeEvent.text;

        const formattedPassword = password.replace(/^\s+/g, ''); // Remove whitespace from start

        setFieldValue(nativeID, formattedPassword);
      },
      [nativeID, setFieldValue]
    );

    return (
      <Input
        placeholder={placeholder}
        label={label}
        isInvalid={isInvalid}
        type={showPassword ? 'text' : 'password'}
        nativeID={nativeID}
        onChange={handleOnChangePassword}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        errorMessage={errorMessage}
        InputRightElement={
          isEyeIconVisible ? (
            <EyeIconElement
              nativeID={nativeID}
              handleShowPass={handleShowPass}
              showPassword={showPassword}
              hasValue={value?.length ? true : false}
            />
          ) : undefined
        }
        {...rest}
      />
    );
  }
);

InputPassword.displayName = 'InputPassword';

export default memo(InputPassword);
