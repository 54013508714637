import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { Stack } from 'native-base';

import { ApiStatus } from 'Enums';

import { useApiError, useAuthError } from 'Hooks';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { SHOW_MODAL } from 'Reducers/modal/types';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

import { forgotPass } from 'Services/auth';

import { RootState } from 'src/reducers';

import ForgotPassFirstStep from './steps/ForgotPassFirstStep';
import ForgotPassSecondStep from './steps/ForgotPassSecondStep';

const mapStateToProps = ({ env: { osType, osVersion, app, appVersion } }: RootState) => {
  return {
    osType,
    osVersion,
    app,
    appVersion,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const ForgotPasswordPage: React.FC<Props> = ({ osType, osVersion, app, appVersion }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState<{ email: string }>({
    email: '',
  });

  const { showApiErrorModal } = useApiError();

  const { showHcpError } = useAuthError();

  const showForgotPasswordBlockedMessage = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.youReached"
              mb={1}
            >{`${i18n.t('ForgotPassword.modals.forgotErrors.youReached')}`}</Text>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.consider"
              mb={1}
            >{`${i18n.t('ForgotPassword.modals.forgotErrors.consider')}`}</Text>
          </Stack>
        ),
      },
    });
  }, []);

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.FORBIDDEN: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
          }

          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS:
          showForgotPasswordBlockedMessage();
          break;
        default:
          showApiErrorModal();
      }
    },
    [showApiErrorModal, showForgotPasswordBlockedMessage, showHcpError]
  );

  const handleSubmitForgotPass = useCallback(
    (email: string) => {
      return new Promise<void>((resolve, reject) => {
        setLoading(true);
        forgotPass(email, osType, osVersion, app, appVersion)
          .then(resolve)
          .catch((error: AxiosError<ApiErrorData>) => {
            handleApiRequestError(error);
            reject(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [osType, osVersion, app, appVersion, handleApiRequestError]
  );

  const makeSteps = useMemo(() => {
    return [
      {
        component: ForgotPassFirstStep,
        componentName: 'ForgotPassFirstStep',
      },
      {
        component: ForgotPassSecondStep,
        componentName: 'ForgotPassSecondStep',
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard
          steps={makeSteps}
          initialValues={initialValues}
          handleSubmitForgotPass={handleSubmitForgotPass}
          loading={loading}
        />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default connector(ForgotPasswordPage);
