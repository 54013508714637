import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { SingleButton as Link } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { Flex, Stack } from 'native-base';

import { ApiStatus } from 'Enums';

import { useApiError } from 'Hooks';

import { Button } from 'Components/utility/Button';
import Footer from 'Components/utility/Footer';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import Main from 'Components/utility/Main';
import OptInOut from 'Components/utility/OptInOut';
import { RichText } from 'Components/utility/RichText/RichText';
import ScrollContainer from 'Components/utility/ScrollContainer';
import Spinner from 'Components/utility/Spinner';

import { AUTH_SESSION } from 'Reducers/auth/types';
import { RootState } from 'Reducers/index';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';
import { store } from 'Utilities/store';

import { handleConsent } from 'Services/consent';

const mapStateToProps = ({
  env: { osType, osVersion, app, appVersion },
  auth,
  nav: { query },
}: RootState) => {
  return {
    osType,
    osVersion,
    app,
    appVersion,
    auth,
    isAccountProfile: query.accountProfile === 'true' ? true : false,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const RWEConsent: React.FC<Props> = ({
  osType,
  osVersion,
  isAccountProfile,
  app,
  appVersion,
  auth,
}) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const handleClickLink = (href: string) => {
    mediator.publish('router:navigate', href);
  };

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.UNAUTHORIZED:
          logout();
          break;
        default:
          showApiErrorModal();
      }
    },
    [showApiErrorModal]
  );

  const handleConsentWrapper = useCallback(
    async (action: string) => {
      return new Promise<AuthSession>((resolve, reject) => {
        handleConsent(
          auth.account_id,
          action,
          osType,
          osVersion,
          app,
          appVersion,
          'realWorldEvidence'
        )
          .then((resp) => {
            const updatedAuthSession: AuthSession = {
              ...auth,
              include: {
                patient: {
                  ...auth.include.patient,
                  consents: { ...auth.include.patient.consents, realWorldEvidence: { ...resp } },
                },
              },
            };

            store.dispatch({ type: AUTH_SESSION, authSession: updatedAuthSession });

            resolve(updatedAuthSession);
          })
          .catch((error: AxiosError<ApiErrorData>) => {
            handleApiRequestError(error);
            reject(error);
          });
      });
    },
    [app, appVersion, auth, handleApiRequestError, osType, osVersion]
  );

  const handleSubmitRWEAction = useCallback(
    async (action: string) => {
      try {
        setLoading(true);

        const updatedAuthSession = await handleConsentWrapper(action);

        notifyApp<AuthSession>('adc-webview:signin', updatedAuthSession);

        // This timeout is added to give the app time to redirect the user back.
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      } catch (err) {
        setLoading(false);
      }
    },
    [handleConsentWrapper]
  );

  return (
    <Main>
      {loading && <Spinner />}
      <ActionBarHeader
        nativeIDTitle={isAccountProfile ? 'Research.title' : 'Research.actionTitle'}
        nativeIDPressable="Research.close"
        title={i18n.t<string>('Research.title')}
        noActionButton={!isAccountProfile}
        defaultActionButton="close"
        fixed={isAccountProfile}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="Research.title"
          title={i18n.t<string>('Research.title')}
          visible={!isAccountProfile}
          noActionBar
        />
        <Flex
          paddingX={4}
          paddingBottom={10}
          overflow="scroll"
          overflowY="auto"
          style={{
            marginTop: isAccountProfile ? 60 : 20,
          }}
        >
          <RichText
            onPressLink={handleClickLink}
            text={i18n.t<string>('Research.content.researchRichText.bodyText')}
          />
        </Flex>
      </ScrollContainer>

      <Footer
        visible={!isAccountProfile}
        customProps={
          <Flex alignSelf="flex-end" w="100%">
            <Button onPress={() => handleSubmitRWEAction('accept')} nativeID="RWE.optIn">
              {i18n.t<string>('Global.microcopy.common.optIn')}
            </Button>
            <Stack mt={4}>
              <Link
                variant="textPrimary"
                onPress={() => handleSubmitRWEAction('decline')}
                text={i18n.t<string>('Global.microcopy.common.decline')}
                testIDText="Global.microcopy.common.decline"
              />
            </Stack>
          </Flex>
        }
      />

      <OptInOut
        isVisible={isAccountProfile}
        optionStrings={{
          titleTag: 'Global.microcopy.common.status',
          positiveTag: 'Global.microcopy.common.optedIn',
          negativeTag: 'Global.microcopy.common.declined',
          positiveOption: 'Global.microcopy.common.optIn',
          negativeOption: 'Global.microcopy.common.decline',
        }}
        initialState={
          !!auth.include.patient?.consents?.realWorldEvidence?.accepted ? 'accept' : 'decline'
        }
        handleConsent={handleConsentWrapper}
      />
    </Main>
  );
};

export default connector(RWEConsent);
