import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

import Main from 'Components/utility/Main';
import Spinner from 'Components/utility/Spinner';

import { AUTH_SESSION } from 'Reducers/auth/types';

import { notifyApp } from 'Utilities/appCommunicator';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

import { getPatient } from 'Services/patient';

import { RootState } from 'src/reducers';

const mapStateToProps = ({
  nav: {
    query: { token, osType, osVersion, app, appVersion },
  },
}: RootState) => {
  return {
    token,
    osType,
    osVersion,
    app,
    appVersion,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const VerifyConsents: React.FC<Props> = ({ token, osType, osVersion, app, appVersion }) => {
  const [loading, setLoading] = useState(false);

  const handleError = useCallback((error?: AxiosError<ApiErrorData>) => {
    notifyApp('adc-webview:navigation', error);

    // Give the app time to redirect the user back
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const verifyConsents = useCallback(
    (token: string) => {
      setLoading(true);

      const decodedToken = jwtDecode<{ id: string; did: string; exp: number; iat: number }>(token);

      getPatient(decodedToken.id, osType, osVersion, app, appVersion)
        .then((patient) => {
          // Store the user session
          const authSession: AuthSession = {
            access_token: token,
            account_id: decodedToken.id,
            expires: decodedToken.exp,
            include: {
              patient,
            },
          };

          persistAuthSession({
            access_token: authSession.access_token,
            account_id: authSession.account_id,
            expires: authSession.expires,
          });

          store.dispatch({ type: AUTH_SESSION, authSession });

          if (!patient.consents?.hipaa) {
            mediator.publish('router:navigate', '/hipaa');
            return;
          }

          if (!patient.consents?.realWorldEvidence) {
            mediator.publish('router:navigate', '/rwe-consent');
            return;
          }

          handleError();
        })
        .catch((error: AxiosError<ApiErrorData>) => {
          log.error('Failed to load the consents', error);
          handleError(error);
        });
    },
    [app, appVersion, handleError, osType, osVersion]
  );

  useEffect(() => {
    if (token) {
      verifyConsents(token);
    }
  }, [token, verifyConsents]);

  return <Main>{loading && <Spinner />}</Main>;
};

export default connector(VerifyConsents);
