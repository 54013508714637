import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { SingleButton as Link } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useApiError, useAuthError } from 'Hooks';

import Banner from 'Components/utility/Banner';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import { Input } from 'Components/utility/Input';
import InputPassword from 'Components/utility/InputPassword';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';
import Spinner from 'Components/utility/Spinner';

import { AUTH_SESSION } from 'Reducers/auth/types';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { signIn } from 'Services/auth';

import { RootState } from 'src/reducers';

interface MyFormValues {
  email: string;
  password: string;
}

const initialValuesFormik: MyFormValues = { email: '', password: '' };

const mapStateToProps = ({
  env: { deviceId, osType, osVersion, app, appVersion },
  nav: { redirection },
}: RootState) => {
  return {
    deviceId,
    osType,
    osVersion,
    app,
    appVersion,
    redirection,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Login: React.FC<Props> = ({ deviceId, osType, osVersion, app, appVersion }) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const {
    handleLoginError,
    handleLockoutError,
    showHcpError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
    showSessionManagementError,
  } = useAuthError('login');

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t<string>('Login.content.signInForm.formField.email.errors.emailAddressValidEmail')
      )
      .required(i18n.t<string>('Login.content.signInForm.formField.email.errors.required')),
    password: Yup.string().required(
      i18n.t<string>('Login.content.signInForm.formField.email.errors.required')
    ),
  });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>, resetForm: () => void, signInRequest: () => void) => {
      switch (error.status) {
        case ApiStatus.UNAUTHORIZED: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
            return;
          }

          if (code === ApiStatus.SESSION_MANAGEMENT_ERROR) {
            showSessionManagementError(signInRequest);
            return;
          }

          handleLoginError(error, resetForm);
          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS: {
          handleLockoutError(error);
          resetForm();
          break;
        }

        default:
          showApiErrorModal();
      }
    },
    [
      handleLockoutError,
      handleLoginError,
      showApiErrorModal,
      showHcpError,
      showSessionManagementError,
    ]
  );

  const signInRequest = useCallback(
    async (formValues: MyFormValues, resetForm: () => void, force?: boolean) => {
      try {
        setLoading(true);

        const { email, password } = formValues;

        const authSession = await signIn(
          email,
          password.trim(),
          osType,
          osVersion,
          app,
          appVersion,
          deviceId,
          force
        );

        if (!authSession.include.patient?.consents?.hipaa) {
          store.dispatch({ type: AUTH_SESSION, authSession });
          mediator.publish('router:navigate', '/hipaa');
          return;
        }

        if (!authSession.include.patient?.consents?.realWorldEvidence) {
          store.dispatch({ type: AUTH_SESSION, authSession });
          mediator.publish('router:navigate', '/rwe-consent');
          return;
        }

        notifyApp<AuthSession>('adc-webview:signin', authSession);

        // This timeout is added to give the app time to redirect the user back.
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      } catch (err) {
        setLoading(false);

        const error = err as AxiosError<ApiErrorData>;

        handleApiRequestError(error, resetForm, () => signInRequest(formValues, resetForm, true));
      }
    },
    [app, appVersion, deviceId, handleApiRequestError, osType, osVersion]
  );

  const onSubmitForm = useCallback(
    async (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
      signInRequest(values, resetForm).finally(() => {
        setSubmitting(false);
      });
    },
    [signInRequest]
  );

  const handleClickForgotPass = useCallback(() => {
    mediator.publish('router:navigate', '/forgot-password');
  }, []);

  const handleClickCreateAccount = useCallback(() => {
    /*
      Need to use the redirect method to replaces the current location in browser history.
      When the user clicks the back button on the create account page, it should redirect the user back to the app.
    */
    mediator.publish('router:redirect', '/create-account');
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: loginSchema,
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  const appName = i18n.t<string>('Global.appNames.libreView');

  return (
    <Main>
      {loading && <Spinner />}
      <ActionBarHeader
        nativeIDPressable="Auth.action-go-back"
        nativeIDTitle="Auth.actionSignInTitle"
        title={i18n.t<string>('Login.title')}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDPressable="Auth.go-back"
          nativeIDTitle="Auth.signInTitle"
          nativeIDSubtitle="Auth.useFreestyleAccount"
          title={i18n.t<string>('Login.title')}
          subtitle={i18n.t<string>('Login.subtitle', { appName })}
        />
        <Banner message={lockoutMessage} submessage={lockoutSubmessage} mt={3} />
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={i18n.t<string>('Login.content.signInForm.formField.email.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.email.label')}
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            nativeID="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            keyboardType="email-address"
            isDisabled={isLocked}
          />
          <InputPassword
            placeholder={i18n.t<string>('Login.content.signInForm.formField.password.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.password.label')}
            isInvalid={errors.password && touched.password ? true : false}
            errorMessage={errors.password}
            nativeID="password"
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            value={values.password}
            isEyeIconVisible={!isLocked}
            isDisabled={isLocked}
          />
          <Link
            variant="textPrimary"
            testIDText="Auth.forgotPassword"
            onPressIn={handleClickForgotPass}
            onPress={handleClickForgotPass}
            disabled={isLocked}
            text={i18n.t<string>('Login.content.signInForm.secondaryText')}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Login.content.footerCreateAccount.links.signIn.label')}
        onButtonSubmit={handleSubmit}
        nativeIDButton="Auth.signIn"
        linkText={i18n.t<string>('Login.content.footerCreateAccount.primaryText')}
        onButtonLinkClick={handleClickCreateAccount}
        nativeIDButtonLink="Auth.createAccount"
        buttonLinkText={i18n.t<string>(
          'Login.content.footerCreateAccount.links.createAccount.label'
        )}
        isButtonDisabled={!values.email || !values.password || isLocked || !isValid || isSubmitting}
      />
    </Main>
  );
};

export default connector(Login);
