/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { useToken } from 'native-base';

type LinkProps = {
  onPressLink?: (href: string) => void;
  href?: string;
  target?: string;
  children?: ReactElement;
};

const Link = ({ onPressLink, href, target, children }: LinkProps) => {
  const [textActive] = useToken('colors', ['interactive.text.active']);
  const [fontSize] = useToken('fontSizes', ['bodyBaseDefault']);

  if (onPressLink && href && !href?.startsWith('https')) {
    return (
      <a
        // this href is here only to satisfy eslint rule anchor-is-valid
        // how we use the preventDefault the 'a' tag doesn't trigger what is triggered is the onPressLink
        href={href}
        id={href}
        style={{
          color: `${textActive}`,
          fontSize: `${fontSize}px`,
          overflow: 'visible',
          width: 'auto',
          background: 'none',
          margin: 0,
          padding: 0,
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        onClick={(e) => {
          e.preventDefault();
          return onPressLink(href);
        }}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      id={href}
      target={target}
      href={href ? href : ''}
      style={{
        color: `${textActive}`,
        textDecoration: 'none',
      }}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const useDefaultTransComponents = (
  onPressLink?: (href: string) => void,
  customNodes?: { strong?: ReactElement; a?: ReactElement }
) => {
  const [fontFamily] = useToken('fonts', ['bodyBaseDefault']);
  const [fontWeightStrong] = useToken('fontWeights', ['bodyBaseStrong']);
  const [fontSize] = useToken('fontSizes', ['bodyBaseStrong']);

  return {
    strong: <Text style={{ fontFamily, fontWeight: fontWeightStrong, fontSize }} />,
    a: <Link onPressLink={onPressLink} />,
    ...customNodes,
  };
};

export default useDefaultTransComponents;
