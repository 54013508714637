import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { Image } from 'native-base';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useAccount, useApiError } from 'Hooks';

import PasswordRequirements from 'Components/register/PasswordRequirements';
import { Button } from 'Components/utility/Button';
import Form from 'Components/utility/Form';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import InputPassword from 'Components/utility/InputPassword';
import Main from 'Components/utility/Main';
import ResponsiveContainer from 'Components/utility/ResponsiveContainer';
import Spinner from 'Components/utility/Spinner';

import i18n from 'Utilities/i18n';

import { resetPass } from 'Services/auth';

import { RootState } from 'src/reducers';

interface MyFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

const initialValuesFormik: MyFormValues = {
  newPassword: '',
  repeatNewPassword: '',
};

const mapStateToProps = ({
  config: { validation },
  nav: {
    query: { email, token, product, version },
  },
}: RootState) => {
  return {
    validation,
    email,
    token,
    product,
    version,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & { next: () => void };

const ResetPasswordFirstStep: React.FC<Props> = ({
  validation,
  email,
  token,
  product,
  version,
  next,
}) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const { showPasswordValidationError } = useAccount();

  const identityMinChar = validation?.identity.filter((identity) => identity.code === 13)[0];

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().min(identityMinChar?.data?.value).required('required').trim(),
    repeatNewPassword: Yup.string()
      .required(
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      )
      .oneOf(
        [Yup.ref('newPassword')],
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      )
      .trim(),
  });

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
    setTouched,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: onSubmitForm,
    validationSchema,
    validateOnBlur: true,
  });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.BAD_REQUEST: {
          const errors = error.response?.data.errors;

          setFieldValue('newPassword', '');
          setFieldValue('repeatNewPassword', '');

          setTouched({});

          showPasswordValidationError({ validation }, errors);
          break;
        }

        default:
          showApiErrorModal();
      }
    },
    [setFieldValue, setTouched, showApiErrorModal, showPasswordValidationError, validation]
  );

  function onSubmitForm() {
    setLoading(true);
    resetPass(values.newPassword, token, product, version)
      .then(next)
      .catch(handleApiRequestError)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Main>
      {loading && <Spinner />}
      <Image
        position={'absolute'}
        zIndex={1}
        resizeMode="contain"
        alt=""
        mt={5}
        width={220}
        height={50}
        alignSelf="center"
        nativeID="Global.microcopy.common.FSLLogowithButterfly"
        source={{
          uri: i18n.t<string>('Global.microcopy.common.FSLLogowithButterfly'),
        }}
      />
      <ResponsiveContainer pt={5}>
        <ContentHeader
          nativeIDTitle="ResetPassword.title"
          nativeIDSubtitle="ResetPassword.subtitle"
          title={i18n.t<string>('ResetPassword.title')}
          subtitle={i18n.t<string>('ResetPassword.subtitle')}
          textAlign="center"
          noActionBar
        />
        <Text
          nativeID="ResetPassword.title-email"
          color="text.80"
          fontSize="l"
          fontWeight="bodyBaseMedium"
          fontFamily="bodyBaseMedium"
          textAlign="center"
        >
          {email}
        </Text>
        <Form onSubmit={handleSubmit} style={{ padding: 0 }}>
          <InputPassword
            mt={5}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.label'
            )}
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.placeholder'
            )}
            isInvalid={errors.newPassword && touched.newPassword ? true : false}
            nativeID="newPassword"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.newPassword}
            onFocus={() => {
              setFieldTouched('newPassword', false);
            }}
          />
          <PasswordRequirements
            isInvalid={errors.newPassword && touched.newPassword ? true : false}
            identities={validation?.identity}
            password={values.newPassword}
          />
          <InputPassword
            mt={1}
            mb={5}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.label'
            )}
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.placeholder'
            )}
            isInvalid={errors.repeatNewPassword && touched.repeatNewPassword ? true : false}
            errorMessage={errors.repeatNewPassword}
            nativeID="repeatNewPassword"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.repeatNewPassword}
          />
          <Button
            nativeID="ResetPassword.button"
            onPress={() => handleSubmit()}
            isDisabled={!values.newPassword || !values.repeatNewPassword || !isValid}
          >
            {i18n.t<string>('ResetPassword.title')}
          </Button>
        </Form>
      </ResponsiveContainer>
    </Main>
  );
};

export default connector(ResetPasswordFirstStep);
