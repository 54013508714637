import React, { memo } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Divider, Flex, Pressable, View } from 'native-base';

import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ImageButton from 'Components/utility/ImageButton';
import Main from 'Components/utility/Main';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

const consentsItems = [
  {
    text: 'Consents.microcopy.consentsOptions.healthcareCommunications',
    onPress: () => mediator.publish('router:navigate', '/hipaa?accountProfile=true'),
  },
  {
    text: 'Consents.microcopy.consentsOptions.researchConsent',
    onPress: () => mediator.publish('router:navigate', '/rwe?accountProfile=true'),
  },
];

type ArrowIconElementProps = {
  onPress: () => void;
  nativeID: string;
};

const ArrowIconElement: React.FC<ArrowIconElementProps> = memo(({ onPress, nativeID }) => {
  return (
    <ImageButton
      nativeID={nativeID}
      imageUriKey="Global.microcopy.common.chevronRightLight"
      tintColor="interactive.text.active"
      onPress={onPress}
      imageProps={{
        size: 3,
        width: 2,
      }}
      pressableProps={{}}
    />
  );
});

ArrowIconElement.displayName = 'ArrowIconElement';

const ConsentWrapper = ({
  isFirst,
  isLast,
  consentText,
  onPress,
}: {
  isFirst: boolean;
  isLast: boolean;
  consentText: string;
  onPress: () => void;
}) => {
  return (
    <Pressable onPress={onPress}>
      <Flex
        nativeID={`${consentText}`}
        p="4"
        bg="neutral.container"
        flexDir={'row'}
        borderTopRadius={isFirst ? 'lg' : 'none'}
        borderBottomRadius={isLast ? 'lg' : 'none'}
      >
        <Text nativeID={`${consentText}-text`} color="text.100" fontToken="bodyBaseMedium" flex={1}>
          {i18n.t<string>(consentText)}
        </Text>

        <Flex justify="center ">
          <ArrowIconElement onPress={onPress} nativeID={`${consentText}-icon`} />
        </Flex>
      </Flex>
    </Pressable>
  );
};

const ConsentsScreen: React.FC = () => {
  return (
    <Main px="4">
      <ActionBarHeader
        nativeIDTitle={'Consents.title'}
        nativeIDPressable="Consents.title-Pressable"
        title={i18n.t<string>('Consents.title')}
        noActionButton={false}
        defaultActionButton="back"
        fixed={true}
      />

      <Flex mt="16">
        {consentsItems.map((item, index) => (
          <View key={item.text}>
            <ConsentWrapper
              isFirst={index === 0}
              isLast={index === consentsItems.length - 1}
              consentText={item.text}
              key={item.text}
              onPress={item.onPress}
            />
            {index < consentsItems.length - 1 && <Divider bg="neutral.20" />}
          </View>
        ))}
      </Flex>
    </Main>
  );
};

export default ConsentsScreen;
