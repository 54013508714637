import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import LandingPage from 'Components/LandingPage';
import ForgotPasswordPage from 'Components/auth/ForgotPassword';
import LoginPage from 'Components/auth/Login';
import ResetPassword from 'Components/auth/ResetPassword';
import ConsentsScreen from 'Components/consents/ConsentsScreen';
import Document from 'Components/consents/Document';
import HipaaConsent from 'Components/consents/HipaaConsent';
import RWEConsent from 'Components/consents/RWEConsent';
import VerifyConsents from 'Components/consents/VerifyConsents';
import RegisterPage from 'Components/register/CreateNewUser';
import AccountInformation from 'Components/settings/AccountInformation';
import UpdatePasswordPage from 'Components/settings/UpdatePassword';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';
import Modal from 'Components/utility/Modal/GeneralModal';

import { RootState } from 'src/reducers';

const mapState = ({ nav: { location, initErrorStatus, queryString } }: RootState) => {
  return {
    onLogin: !!location.match(/^\/login/i),
    onForgotPass: !!location.match(/^\/forgot-password/i),
    onCreateAccount: !!location.match(/^\/create-account/i),
    onAccountInformation: !!location.match(/^\/account-information/i),
    onUpdatePassword: !!location.match(/^\/update-password/i),
    onConsents: !!location.match(/^\/consents/i),
    onResetPassword: !!location.match(/^\/reset-password/i),
    onHipaa: !!location.match(/^\/hipaa/i),
    onRWE: !!location.match(/^\/rwe/i),
    onTermsOfUse: !!location.match(/^\/terms-of-use/i),
    onPrivacyNotice: !!location.match(/^\/privacy-notice/i),
    onVerifyConsents: !!location.match(/^\/verify-consents/i),
    initErrorStatus,
    queryString,
  };
};

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const App: React.FC<Props> = ({
  onLogin,
  onForgotPass,
  onCreateAccount,
  onAccountInformation,
  onUpdatePassword,
  onResetPassword,
  onHipaa,
  onRWE,
  onConsents,
  onTermsOfUse,
  onPrivacyNotice,
  onVerifyConsents,
  initErrorStatus,
  queryString,
}) => {
  let content: React.ReactNode = null;

  if (initErrorStatus) {
    content = (
      <InitializationErrorPage errorStatus={initErrorStatus} navigationQueryString={queryString} />
    );
  } else if (onLogin) {
    content = <LoginPage />;
  } else if (onForgotPass) {
    content = <ForgotPasswordPage />;
  } else if (onConsents) {
    content = <ConsentsScreen />;
  } else if (onCreateAccount) {
    content = <RegisterPage />;
  } else if (onAccountInformation) {
    content = <AccountInformation />;
  } else if (onUpdatePassword) {
    content = <UpdatePasswordPage />;
  } else if (onResetPassword) {
    content = <ResetPassword />;
  } else if (onHipaa) {
    content = <HipaaConsent />;
  } else if (onRWE) {
    content = <RWEConsent />;
  } else if (onTermsOfUse) {
    content = <Document type="toufsll" />;
  } else if (onPrivacyNotice) {
    content = <Document type="pnpat" />;
  } else if (onVerifyConsents) {
    content = <VerifyConsents />;
  } else {
    content = <LandingPage />;
  }

  return (
    <>
      <Modal />
      {content}
    </>
  );
};

export default connector(App);
