export const SET_PHONE_LANGUAGE = 'SET_PHONE_LANGUAGE';

export interface EnvState {
  osType: 'android' | 'iOS' | 'none';
  osVersion: string;
  appVersion: string;
  deviceId: string;
  theme: 'polarisLight' | 'polarisDark';
  app: string;
  deviceManufacturer: string;
  deviceModel: string;
  country: string;
  phoneLanguage: string;
}

interface SetPhoneLanguageAction {
  type: typeof SET_PHONE_LANGUAGE;
  phoneLanguage: string;
}

export type EnvActions = SetPhoneLanguageAction;
