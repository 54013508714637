import React, { ReactElement } from 'react';

import { IImageProps, IPressableProps, Image, Pressable, useToken } from 'native-base';

import { useGetAsset } from 'Hooks';

type Props = {
  nativeID?: string;
  className?: string;
  onPress: () => void;
  imageProps?: IImageProps;
  pressableProps: IPressableProps;
  imageUriKey?: string;
  children?: ReactElement;
  tintColor?: string;
};

const ImageButton: React.FC<Props> = ({
  nativeID,
  className,
  onPress,
  pressableProps,
  imageProps,
  imageUriKey,
  children,
  tintColor,
}) => {
  const { getAssetInColorScheme } = useGetAsset();

  const [iconColor] = useToken('colors', [
    tintColor ? tintColor : 'interactive.primary.active.default',
  ]);

  return (
    // the color prop is used for the automation tests to match with Figma design
    <div className={className} id={`${nativeID}-container`} color={iconColor}>
      <Pressable nativeID={nativeID} onPress={onPress} {...pressableProps}>
        {children ? (
          children
        ) : (
          <Image
            source={{
              uri: imageUriKey ? getAssetInColorScheme(imageUriKey) : '',
            }}
            tintColor={iconColor}
            {...imageProps}
          />
        )}
      </Pressable>
    </div>
  );
};

export default ImageButton;
