import React, { useCallback } from 'react';

import { SingleButton as Link, Text } from '@adc-polaris-component-library/component-library';
import { Box, Flex, Image, Stack } from 'native-base';

import Footer from 'Components/utility/Footer';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import Main from 'Components/utility/Main';
import Spinner from 'Components/utility/Spinner';
import { NavigationProps } from 'Components/wizard';

import { SHOW_MODAL } from 'Reducers/modal/types';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { getAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

export interface FormValues {
  email: string;
}

type Props = {
  values: FormValues;
  handleSubmitForgotPass(email: string): Promise<void>;
  loading: boolean;
} & NavigationProps<FormValues>;

const ForgotPassSecondStep: React.FC<Props> = ({
  values,
  handleSubmitForgotPass,
  loading,
  back,
}) => {
  const handleClickDone = () => {
    if (getAuthSession()) {
      notifyApp('adc-webview:navigation');
      return;
    }

    mediator.publish('router:navigate', '/login');
  };

  const checkYourEmail = i18n.t<string>(
    'CheckYourEmail.content.checkYourEmail.media.checkYourEmail'
  );

  const showConfirmationEmailMessage = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.sentInstructions"
              mb={1}
            >
              {i18n.t<string>('ForgotPassword.modals.forgotErrors.sentInstructions')}
            </Text>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.notSeeingEmail"
              mb={1}
            >
              {i18n.t<string>('ForgotPassword.modals.forgotErrors.notSeeingEmail')}
            </Text>
          </Stack>
        ),
      },
    });
  }, []);

  const handleResendEmail = () => {
    handleSubmitForgotPass(values.email)
      .then(showConfirmationEmailMessage)
      .catch((err) => {
        log.error(err);
      });
  };

  return (
    <Main>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ContentHeader
            nativeIDTitle="ForgotPass.checkYourEmail"
            nativeIDSubtitle="ForgotPass.instructionsResetSendTo"
            nativeIDPressable="ForgotPass.step2-go-back"
            title={i18n.t<string>('CheckYourEmail.title')}
            subtitle={i18n.t<string>('CheckYourEmail.subtitle')}
            onBack={back}
          />
          <Text
            nativeID="ForgotPass.step2-user-email"
            paddingX={4}
            w="90%"
            color="text.80"
            fontSize="l"
            fontWeight={'bodyBaseMedium'}
            fontFamily={'bodyBaseMedium'}
          >
            {values.email}
          </Text>

          <Image
            size="container"
            resizeMode="center"
            width="100vw"
            height="40%"
            nativeID="ForgotPass.step2-check-email-img"
            mx="auto"
            source={{
              uri: checkYourEmail,
            }}
            alt="an open letter, a open envelop with a paper inside"
          />

          <Flex
            marginX="auto"
            flex={1}
            justifyContent="space-between"
            w="100%"
            flexDirection="column"
          >
            <Box alignItems="center">
              <Text
                nativeID="ForgotPass.notReceiveEmail"
                w="90%"
                textAlign="center"
                color="text.80"
                fontSize="md"
                fontWeight={'bodyBaseDefault'}
                fontFamily={'bodyBaseDefault'}
              >
                {i18n.t<string>('CheckYourEmail.content.checkYourEmail.primaryText')}
              </Text>
              <Link
                variant="textPrimary"
                testIDText="Global.resendEmail"
                onPress={handleResendEmail}
                text={i18n.t<string>(
                  'CheckYourEmail.content.checkYourEmail.links.resendEmail.label'
                )}
              />
            </Box>
            <Footer
              buttonText={i18n.t<string>('CheckYourEmail.content.checkYourEmail.links.done.label')}
              onButtonSubmit={handleClickDone}
              nativeIDButton="Global.done"
            />
          </Flex>
        </>
      )}
    </Main>
  );
};

export default ForgotPassSecondStep;
